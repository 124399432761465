<template>
  <div style="height: inherit">

    <b-card>
      <b-row>
        <b-col
          v-if="coaches.length !== 1"
          class="align-middle my-auto"
        >
          <span class="align-middle">
            <b-badge
              variant="secondary"
              class="ml-1 align-middle"
              @click="selectCoach('')"
            >
              {{ $t('All groups') }}
            </b-badge>
            <b-badge
              v-for="coach in coaches"
              :key="coach"
              variant="secondary"
              class="ml-1 align-middle"
              @click="selectCoach(coach)"
            >
              {{ coach }}
            </b-badge>
          </span>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="success"
            class="btn-icon"
            @click.stop="$router.push({ name: 'new-group' })"
          >
            {{ $t('New Group') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="deleteWarning"
      rounded="sm"
    >
      <b-list-group>
        <b-list-group-item
          v-for="group in groups.filter(item => item.id !== -1)"
          :key="group.id"
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span>
              {{ group.name }}
            </span>
            <b-badge
              v-if="coaches.length !== 1"
              variant="secondary"
              class="ml-1"
              @click="selectCoach(group.coach)"
            >
              {{ group.coach }}
            </b-badge>
          </div>
          <div>
            <span class="mr-1">
              {{ group.players_number }} {{ $t('players') }}
            </span><b-button
              variant="primary"
              class="btn-icon mr-1"
              @click="$router.push({ name: 'stats-summary-group', params: { groupId: group.id }})"
            >
              <feather-icon icon="BarChart2Icon" />
            </b-button>
            <b-button
              variant="secondary"
              class="btn-icon mr-1"
              @click="$router.push({ name: 'edit-group', params: { groupId: group.id }})"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="danger"
              class="btn-icon mr-1"
              @click="validarDelete(group)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            {{ $t('Do you want to delete this group') }} : <b>{{ toDelete.name }}</b> ?
          </p>
          <b-button
            ref="delete"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="deletar"
          >
            {{ $t('Delete') }}
          </b-button>
          <b-button
            ref="cancel"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="cancelDelete"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BListGroup, BBadge, BListGroupItem, BButton, BOverlay, BIcon,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// import SpiderTable from '../../components/SpiderTable.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BIcon,

    BButton,
    BListGroup,
    BBadge,
    BListGroupItem,
  },
  data() {
    return {
      searchTerm: '',
      tabs: [1],
      selectedCoach: '',
      tabCounter: 1,
      toDelete: {},
      deleteWarning: false,
    }
  },
  computed: {
    groups() {
      if (this.searchTerm !== '') {
        return (this.$store.state.spider.groups.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
          .filter(item => {
            if (this.selectedCoach === '') {
              return true
            }
            return item.coach === this.selectedCoach
          }))
      }
      return this.$store.state.spider.groups.filter(item => {
        if (this.selectedCoach === '') {
          return true
        }
        return item.coach === this.selectedCoach
      })
    },
    coaches() {
      return this.$store.state.spider.groups.filter(item => item.id !== -1).map(item => item.coach).filter(this.onlyUnique)
    },
    columns() {
      return [
        {
          label: this.$t('Name'),
          field: 'name',
          width: '50%',
        },
        {
          label: this.$t('Players'),
          field: 'players_number',
          width: '25%',
        },
        {
          label: this.$t('Actions'),
          field: 'groups_actions',
          width: '25%',
          tdClass: 'text-center',
        },
      ]
    },
  },
  beforeCreate() {
    store.dispatch('spider/updatePlayers')
    store.dispatch('spider/updateGroups')
  },
  methods: {
    formatHandicap(score) {
      return score === 1000 ? '-' : (Math.round(score * 10) / 10).toFixed(1)
    },
    selectCoach(c) {
      this.selectedCoach = c
    },
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (this.tabs[i] === x) {
          this.tabs.splice(i, 1)
        }
      }
    },
    newTab() {
      this.tabs.push((this.tabCounter += 1))
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
    cancelDelete() {
      this.toDelete = {}
      this.deleteWarning = false
    },
    validarDelete(g) {
      this.toDelete = g
      this.deleteWarning = true
    },
    deletar() {
      this.$store.dispatch('spider/deleteGroup', this.toDelete.id).then(() => {
        this.toDelete = {}
        this.deleteWarning = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Group'),
            icon: 'UserCheckIcon',
            variant: 'danger',
            text: this.$t('Group deleted'),
          },
        })
        this.selectedCoach = ''
        this.$store.dispatch('spider/updateGroups')
      })
        .catch(() => {
          this.toDelete = {}
          this.deleteWarning = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Group'),
              icon: 'UserCheckIcon',
              variant: 'warning',
              text: this.$t('We could not delete this group'),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
.badge {
  cursor: pointer;
}
</style>
